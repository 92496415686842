import React, { Component } from "react";
import Fade from "react-reveal";
//import { Container, Row, Col } from 'fluid-react';


class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  render() {
    // if (!this.props.data) return null;

    // const skillmessage = this.props.data.skillmessage;
    // const education = this.props.data.education.map(function (education) {
    //   return (
    //     <div key={education.school}>
    //       <h3>{education.school}</h3>
    //       <p className="info">
    //         {education.degree} <span></span>
    //         <em className="date">{education.graduated}</em>
    //       </p>
    //       <p>{education.description}</p>
    //     </div>
    //   );
    // });

    // const work1 = this.props.data.work1.map(function (work1) {
    //   return (
    //     <div key={work1.company}>
    //       <h3>{work1.company}</h3>
    //       <p className="info">
    //         {work1.title}
    //         <span></span> <em className="date">{work1.years}</em>
    //       </p>
    //       <p>{work1.description}</p>
    //     </div>
    //   );
    // });

    // const work2 = this.props.data.work2.map(function (work2) {
    //   return (
    //     <div key={work2.company}>
    //       <h3>{work2.company}</h3>
    //       <p className="info">
    //         {work2.title}
    //         <span></span> <em className="date">{work2.years}</em>
    //       </p>
    //       <p>{work2.description}</p>
    //     </div>
    //   );
    // });

    // const work3 = this.props.data.work3.map(function (work3) {
    //   return (
    //     <div key={work3.company}>
    //       <h3>{work3.company}</h3>
    //       <p className="info">
    //         {work3.title}
    //         <span></span> <em className="date">{work3.years}</em>
    //       </p>
    //       <p>{work3.description}</p>
    //     </div>
    //   );
    // });

    // const skills = this.props.data.skills.map((skills) => {
    //   const backgroundColor = this.getRandomColor();
    //   const className = "bar-expand " + skills.name.toLowerCase();
    //   const width = skills.level;

    //   return (
    //     <li key={skills.name}>
    //       <span style={{ width, backgroundColor }} className={className}></span>
    //       <em>{skills.name}</em>
    //     </li>
    //   );
    // });

    return (
      <section id="resume">
        {/* <img style={{marginTop:0}}
                  src="robocza.jpg"
                  alt="Serwis AQUA - ubrania pracowników"
                /> */}
        {/* <Fade duration={1000}> */}
          <div className="row">
            {/* <img style={{marginTop:0}}
                  src="robocza.jpg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />     */}
            {/* <div className="twelve columns main-col">
              <div className="row item"> */}
                <div className="twelve columns">
                <h3>Szanowni Państwo,</h3><br/>
                <h4>Jestem lekarzem ginekologiem-położnikiem oraz specjalistą w dziedzinie ultrasonografii,
                    wieloletnim pracownikiem szpitala klinicznego w Warszawie, w którym zajmuję się
                    specjalistyczną diagnostyką USG. Ukończyłem Wydział Lekarski Akademii Medycznej
                    w Warszawie. Regularnie uczestniczę w szkoleniach i kongresach, a także zjazdach
                    poświęconych głównym obszarom zainteresowań zawodowych.<br/><br/>
                    Posiadam certyfikaty uprawniające do wykonywania badań USG w tym:
                    Certyfikat Sekcji Ultrasonografii Polskiego Towarzystwa Ginekologicznego PTG oraz
                    Certyfikat FMF Londyńskiej Fundacji Medycyny Płodowej.<br/><br/>
                    Podczas mojej dwudziestopięcioletniej praktyki położniczo – ginekologicznej wykonałem 250
                    tysięcy badań ultrasonograficznych. Badania wykonuję przy użyciu nowoczesnego aparatu
                    Voluson E10, firmy General Electric, na którym pacjentka ma możliwość wygodnej obserwacji
                    badania USG na osobnym monitorze.<br/><br/>
                    Poza samym badaniem, którego celem jest informacja medyczna, dla moich pacjentek
                    bardzo ważnym aspektem jest towarzysząca mu atmosfera. Wiem, że są to jedne
                    z najważniejszych chwil w życiu moich pacjentek, dlatego podczas wykonywanego badania
                    pacjentce mogą towarzyszyć bliskie jej osoby.<br/><br/>
                    Dodatkowo, na życzenie pacjentki badanie USG może być nagrane na płytę lub pendrive.<br/><br/>
                    Zapraszam do mojego gabinetu, zapoznania się z opiniami pacjentek na portalu „znany
                    lekarz” oraz zachęcam Państwa do pobrania mojej aplikacji, która pozwoli na szybki
                    i bezpośredni kontakt ze mną.<br/><br/>
                </h4>
                </div>
                <div className="seven columns"></div>
                <div className="five columns main-col"><img src="podpis.png" alt="dr Robert Makowski"/></div>
                <div className="seven columns"></div>
                <div className="five columns main-col"><h4>ginekolog dr Robert Makowski</h4></div>
                
                {/* <div className="twelve columns"><img style={{backgroundColor: "white"}} src="certificate.png" alt="dr Robert Makowski"/></div> */}
              </div>
            {/* </div>
          </div>  */}
        {/* </Fade> */}
        
        {/* <img style={{marginTop: 20}}
                  src="reczniki2.jpg"
                  alt="Serwis AQUA - hotele"
                /> */}
        {/* <Fade duration={1000}>
          <div id="hotele" className="row work">
            <div className="header-col">
              <h1>Pobierz aplikację skanując telefonem kod QR</h1>
              <img src="qrcode.png" alt="dr Robert Makowski"/>
              <h1>lub otwórz stronę w telefonie i naciśnij</h1>  
              <button>Pobierz aplikację >></button>
            </div>
            
            {/* <div className="twelve columns main-col">{work1}</div> */}
          {/* </div>
        </Fade> */}

        {/* <img style={{marginTop: 20}}
                  src="truck.jpeg"
                  alt="Serwis AQUA - pralnia"
                /> */}
        {/* <Fade duration={1000}>
          <div id="klient" className="row skill">
            <div className="twelve columns header-col"> */}
              {/* <h1 style={{color: "#0344ad"}}>
                Obsługa Klienta indywidualnego
              </h1> */}
              {/* </div>
              <img style={{marginTop: 20}}
                  src="truck.jpeg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />
              <div className="twelve columns main-col">{work2}
            </div>
            </div>
        </Fade> */}

        {/* <img style={{marginTop: 20}}
                  src="truck.jpeg"
                  alt="Serwis AQUA - pralnia"
                /> */}
        {/* <Fade duration={1000}>
          <div id="dywany" className="row skill">
            <div className="twelve columns header-col"> */}
              {/* <h1 style={{color: "#0344ad"}}>
                Pralnia dywanów
              </h1> */}
              {/* </div>
              <img style={{marginTop: 20}}
                  src="carpet.jpg"
                  alt="Serwis Aqua - profesjonalna pralnia"
                />
              <div className="twelve columns main-col">{work3}
            </div>
            </div>
        </Fade> */}
      </section>
    );
  }
}

export default Resume;
