import React, { Component } from "react";
import Fade from "react-reveal";

class Footer extends Component {
  render() {
    // if (!this.props.data) return null;

    // const networks = this.props.data.social.map(function (network) {
    //   return (
    //     <li key={network.name}>
    //       <a href={network.url}>
    //         <i className={network.className}></i>
    //       </a>
    //     </li>
    //   );
    // });

    return (
      <footer>
        {/* <div className="row"> */}
          <Fade bottom>
            <div className="twelve columns">
              {/* <ul className="social-links">{networks}</ul> */}
              <h4 style={{color: "#ffffff"}}>Warszawa ul. Żytnia 16 lokal 50 | telefon: +48 502 551 404 | e-mail: kontakt@usgrobertmakowski.pl</h4>
              <ul className="copyright">
                <li>&copy; Copyright 2022 dr Robert Makowski</li>
                <li> Proudly created with{" "}
                  <a title="Styleshout" href="http://www.nectarina.pl">
                    nectarina.pl
                  </a>
                </li>
              </ul>
            </div>
          </Fade>

          <div id="go-top">
            <a className="smoothscroll" title="Back to Top" href="#home">
              <i className="icon-up-open"></i>
            </a>
          </div>
        {/* </div> */}
      </footer>
    );
  }
}

export default Footer;
